import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "@assets/fp_loading.json";

const LoadingSpinner = ({ width = '150px', height = '150px' }) => (
  <div className="flex items-center justify-center h-screen">
    <Lottie animationData={loadingAnimation} loop style={{ width, height }} />
  </div>
);

export default LoadingSpinner;
