/**
 * matchSimulationSlice.js
 *
 * A high-level Redux Toolkit slice that handles football match simulations.
 * It manages the timeline, statistics, final post-match data, and calls external
 * services like "simulate_match" and "match_analysis_generator".
 *
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callFirebaseFunction } from "@/utils/firebaseFunctionCaller";
import { parseSimulationResult } from "@/middleware/parseSimulationResult";
import { annotateTimelineWithScore } from "@/utils/scoreboardUtils";
import { CATEGORY_TYPES } from "@/utils/categories";
import { PHASES_OF_THE_MATCH } from "@/constants/phasesOfTheMatch";
import { updatePlayerStatuses } from "@/features/teamSlice";
import generateRealisticHeatmap from "@/utils/generateRealisticHeatmap";
import generatePositionedPassVectors from "@/utils/generatePositionedPassVectors";
import {
  buildMatchAnalysisPayload,
  assignDefaultCompletionData,
} from "@/utils/dataTransformers";

/**
 * Time step (in seconds) to increment the match clock in the simulation loop.
 */
export const MATCH_TIME_STEP = 27;

/**
 * Maximum match time (in seconds).
 */
export const MAX_MATCH_TIME = 5400;

/**
 * Delay (in milliseconds) between simulation intervals.
 */
export const INTERVAL_DELAY = 100;

const initialState = {
  loadingTimeline: false,
  loadingStatistics: false,
  errorTimeline: null,
  errorStatistics: null,
  timeline: [],
  attackPercentage: [],
  technicianAdvice: null,
  highlights: [],
  simulationCompleted: false,
  hasSimulated: false,
  navigationTriggered: false,
  localShotsData: { field: [], goal: [] },
  opponentShotsData: { field: [], goal: [] },
  playerHeatmaps: {},
  playerGeneratedData: {},
  matchTime: 0,
  isMatchTimeRunning: false,
  phase: PHASES_OF_THE_MATCH.NOT_STARTED,
  scoreboard: {
    local: { goals: [], redCards: [] },
    opponent: { goals: [], redCards: [] },
  },
  completedMatchData: {
    statistics: {
      local: { goals: [], stats: {} },
      opponent: { goals: [], stats: {} },
    },
    penaltyShootout: { local: 0, opponent: 0 },
    postMatchSummary: {
      title: "",
      subtitle: "",
      content: "",
      matchTopPlayers: [],
    },
  },
  processedEventSignatures: {},
  lastProcessedMinute: 0,
};

/**
 * A helper function that calls a Firebase HTTP function,
 * verifying `success` is true before returning.
 */
async function fetchData(fnName, data) {
  const response = await callFirebaseFunction(fnName, data);
  if (!response.success) {
    throw new Error(
      response.message || "Unexpected error in Firebase function."
    );
  }
  return response;
}

/** Converts a "MM:SS" string into total minutes (float). */
function timeToMinutes(str) {
  const [mStr, sStr] = str.split(":");
  const m = Number(mStr) || 0;
  const s = sStr ? Number(sStr) : 0;
  return m + s / 60;
}

/** Ensures the timeline is in ascending chronological order by minute. */
function ensureAscendingTimeline(arr) {
  if (arr.length < 2) return arr;
  const first = timeToMinutes(arr[0].minute);
  const last = timeToMinutes(arr[arr.length - 1].minute);
  return first > last ? arr.slice().reverse() : arr;
}

/** Fills or normalizes the attackPercentage data to a fixed length (e.g. 46 intervals). */
function fillAttack(attackMap, length = 46) {
  const result = [];
  for (let i = 0; i < length; i++) {
    const min = i * 2;
    const existing = attackMap.get(min);
    if (existing) {
      result.push({
        minute: min,
        local: existing.local,
        opponent: existing.opponent,
      });
    } else {
      result.push({
        minute: min,
        local: 1 + Math.floor(Math.random() * 30),
        opponent: 1 + Math.floor(Math.random() * 30),
      });
    }
  }
  return result;
}

/** Generates a signature string for each timeline event to avoid duplicates. */
function buildSignature(evt) {
  const num = evt.player?.number || 0;
  return `${evt.minute}-${evt.category}-${evt.side}-${num}`;
}

/**
 * A small utility to merge new events (goals/cards) into existing arrays,
 * avoiding duplicates by constructing a unique key from player name + minute.
 */
function mergeUniqueEvents(existing = [], incoming = []) {
  const combined = [...existing, ...incoming];
  const uniqueMap = new Map(
    combined.map((ev) => [`${ev.player || ev.name}-${ev.minute}`, ev])
  );
  return Array.from(uniqueMap.values());
}

/**
 * Thunk that retrieves the simulation timeline from "simulate_match".
 */
export const fetchSimulationTimeline = createAsyncThunk(
  "matchSimulation/fetchSimulationTimeline",
  async (matchData, { rejectWithValue }) => {
    try {
      const response = await fetchData("simulate_match", matchData);
      const {
        timeline = [],
        attackPercentage = [],
        playersStatus,
        technicianAdvice,
      } = parseSimulationResult(response) || {};

      if (!Array.isArray(timeline) || timeline.length === 0) {
        throw new Error("No timeline events returned from simulate_match.");
      }

      const ordered = ensureAscendingTimeline(timeline);
      const annotated = annotateTimelineWithScore(ordered);

      const atkMap = new Map(
        attackPercentage.map(({ minute, ...rest }) => [minute, rest])
      );
      const finalAtk = fillAttack(atkMap);

      function toStatusArray(teamData) {
        if (Array.isArray(teamData?.players)) {
          return teamData.players.map((p) => ({
            playerNumber: p.playerNumber,
            status: p.status,
          }));
        }
        return [];
      }

      return {
        timeline: annotated,
        attackPercentage: finalAtk,
        technicianAdvice: technicianAdvice || null,
        playersStatus: {
          localStatuses: toStatusArray(playersStatus?.local),
          opponentStatuses: toStatusArray(playersStatus?.opponent),
        },
      };
    } catch (err) {
      return rejectWithValue(
        err.message || "Failed to fetch simulation timeline."
      );
    }
  }
);

/**
 * Thunk that retrieves final match data from "match_analysis_generator" and
 * inserts default fields via assignDefaultCompletionData.
 */
export const fetchCompleteMatchData = createAsyncThunk(
  "matchSimulation/fetchCompleteMatchData",
  async (matchData, { rejectWithValue }) => {
    try {
      /*console.log(
        "Request match_analysis_generator: ",
        JSON.stringify(matchData)
      );
      console.log(
        "Response match_analysis_generator: ",
        JSON.stringify(response)
      );*/

      const response = await fetchData("match_analysis_generator", matchData);
      return assignDefaultCompletionData(response);
    } catch (err) {
      return rejectWithValue(
        err.message || "Failed to fetch complete match data."
      );
    }
  }
);

/**
 * Orchestration thunk: fetchSimulationTimeline + updatePlayerStatuses.
 */
export const initiateMatchSimulation = createAsyncThunk(
  "matchSimulation/initiateMatchSimulation",
  async (matchData, { dispatch, rejectWithValue }) => {
    try {
      const result = await dispatch(
        fetchSimulationTimeline(matchData)
      ).unwrap();
      const { localStatuses, opponentStatuses } = result.playersStatus;
      dispatch(
        updatePlayerStatuses({
          teamStatuses: {
            local: localStatuses,
            opponent: opponentStatuses,
          },
        })
      );
      return result;
    } catch (err) {
      return rejectWithValue(
        err.message || "Simulation could not be initiated."
      );
    }
  }
);

const matchSimulationSlice = createSlice({
  name: "matchSimulation",
  initialState,
  reducers: {
    initiateSimulation(state) {
      state.hasSimulated = false;
      state.simulationCompleted = false;
      state.errorTimeline = null;
      state.errorStatistics = null;
      state.navigationTriggered = false;
      state.matchTime = 0;
      state.isMatchTimeRunning = false;
      state.technicianAdvice = null;
      state.lastProcessedMinute = 0;
    },
    resetSimulationState() {
      return { ...initialState };
    },
    markNavigationTriggered(state) {
      state.navigationTriggered = true;
    },
    setError(state, { payload }) {
      state.errorTimeline = payload;
      state.errorStatistics = payload;
    },
    storeLocalShotsData(state, { payload }) {
      const { field = [], goal = [] } = payload;
      state.localShotsData = { field, goal };
    },
    storeOpponentShotsData(state, { payload }) {
      const { field = [], goal = [] } = payload;
      state.opponentShotsData = { field, goal };
    },
    storePlayerHeatmap(state, { payload }) {
      const { playerId, heatmapData } = payload;
      state.playerHeatmaps[playerId] = heatmapData;
    },
    setMatchPhase(state, { payload }) {
      state.phase = payload;
    },
    updateScoreboard(state, { payload }) {
      const { local, opponent } = payload;
      if (local) {
        state.scoreboard.local = {
          goals: local.goals ?? state.scoreboard.local.goals,
          redCards: local.redCards ?? state.scoreboard.local.redCards,
        };
      }
      if (opponent) {
        state.scoreboard.opponent = {
          goals: opponent.goals ?? state.scoreboard.opponent.goals,
          redCards: opponent.redCards ?? state.scoreboard.opponent.redCards,
        };
      }
    },
    updateGoals(state, { payload }) {
      const { team, goals } = payload;
      state.scoreboard[team].goals = mergeUniqueEvents(
        state.scoreboard[team].goals,
        goals
      );
    },
    updateRedCards(state, { payload }) {
      const { team, redCards } = payload;
      state.scoreboard[team].redCards = mergeUniqueEvents(
        state.scoreboard[team].redCards,
        redCards
      );
    },
    setLastProcessedMinute(state, { payload }) {
      state.lastProcessedMinute = payload;
    },
    updateCompletedMatchData(state, { payload }) {
      state.completedMatchData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //===================== FETCH SIMULATION TIMELINE =====================
      .addCase(fetchSimulationTimeline.pending, (state) => {
        state.loadingTimeline = true;
        state.errorTimeline = null;
      })
      .addCase(fetchSimulationTimeline.fulfilled, (state, { payload }) => {
        const { FIRST_HALF, SECOND_HALF, EXTRA_TIME, PENALTIES, FULL_MATCH } =
          PHASES_OF_THE_MATCH;

        if (state.phase === FIRST_HALF) {
          state.timeline = ensureAscendingTimeline(payload.timeline);
          state.attackPercentage = payload.attackPercentage;
          state.technicianAdvice = payload.technicianAdvice;
          state.playersStatus = payload.playersStatus;
          state.processedEventSignatures = {};
          state.lastProcessedMinute = state.timeline.length
            ? Number(state.timeline[state.timeline.length - 1].minute)
            : 0;
        } else if ([SECOND_HALF, EXTRA_TIME, PENALTIES].includes(state.phase)) {
          const existingTimes = new Set(state.timeline.map((e) => e.minute));
          const newEvents = payload.timeline.filter(
            (e) => !existingTimes.has(e.minute)
          );
          for (const e of newEvents) {
            const sig = buildSignature(e);
            if (!state.processedEventSignatures[sig]) {
              state.processedEventSignatures[sig] = true;
              state.timeline.push(e);
            }
          }
          state.timeline = ensureAscendingTimeline(state.timeline);

          const atkMap = new Map(
            state.attackPercentage.map(({ minute, ...rest }) => [minute, rest])
          );
          for (const { minute, ...rest } of payload.attackPercentage) {
            if (!atkMap.has(minute)) {
              atkMap.set(minute, rest);
            }
          }
          const combinedAttack = Array.from(atkMap).map(([m, val]) => ({
            minute: m,
            ...val,
          }));
          combinedAttack.sort((a, b) => a.minute - b.minute);
          state.attackPercentage = combinedAttack;

          // Merge updated energy levels
          const mergeEnergy = (prev = [], next = []) => {
            const mp = new Map(prev.map((p) => [p.playerNumber, { ...p }]));
            for (const n of next) {
              const existing = mp.get(n.playerNumber);
              if (existing) {
                mp.set(n.playerNumber, {
                  ...existing,
                  status: {
                    ...existing.status,
                    energyLevel: n.status.energyLevel,
                  },
                });
              } else {
                mp.set(n.playerNumber, n);
              }
            }
            return Array.from(mp.values());
          };
          const prevLocal = state.playersStatus?.localStatuses || [];
          const prevOpp = state.playersStatus?.opponentStatuses || [];
          state.playersStatus = {
            localStatuses: mergeEnergy(
              prevLocal,
              payload.playersStatus.localStatuses
            ),
            opponentStatuses: mergeEnergy(
              prevOpp,
              payload.playersStatus.opponentStatuses
            ),
          };

          if (!state.technicianAdvice) {
            state.technicianAdvice = payload.technicianAdvice;
          }
          state.lastProcessedMinute = state.timeline.length
            ? Math.max(...state.timeline.map((e) => Number(e.minute)))
            : state.lastProcessedMinute;
        } else if (state.phase === FULL_MATCH) {
          state.timeline = ensureAscendingTimeline(payload.timeline);
          state.attackPercentage = payload.attackPercentage;
          state.technicianAdvice = payload.technicianAdvice;
          state.playersStatus = payload.playersStatus;
          state.lastProcessedMinute = state.timeline.length
            ? Math.max(...state.timeline.map((e) => Number(e.minute)))
            : state.lastProcessedMinute;
        }

        // Always annotate the updated timeline with partial scores
        state.timeline = annotateTimelineWithScore(state.timeline);
        state.loadingTimeline = false;
        state.simulationCompleted = false;
        state.hasSimulated = true;

        // Build highlights from relevant categories
        const categoriesToHighlight = [
          CATEGORY_TYPES.START,
          CATEGORY_TYPES.GOAL,
          CATEGORY_TYPES.OWN_GOAL,
          CATEGORY_TYPES.FREE_KICK_GOAL,
          CATEGORY_TYPES.OFFSIDE,
          CATEGORY_TYPES.RED_CARD,
          CATEGORY_TYPES.FULL_TIME,
          CATEGORY_TYPES.YELLOW_CARD,
        ];
        state.highlights = state.timeline.reduce((acc, evt, index) => {
          if (categoriesToHighlight.includes(evt.category)) {
            acc.push({ ...evt, id: index, thumbnail: "" });
          }
          return acc;
        }, []);

        const allLocal = state.playersStatus?.localStatuses || [];
        const allOpp = state.playersStatus?.opponentStatuses || [];
        [...allLocal, ...allOpp].forEach((p) => {
          const passVectors = generatePositionedPassVectors(p.position, 40);
          const heatmapMatrix = generateRealisticHeatmap(
            p.position,
            p.heatMap?.intensity || 3,
            11,
            19
          );
          state.playerGeneratedData[p.playerNumber] = {
            passVectors,
            heatmapMatrix,
          };
        });
      })
      .addCase(fetchSimulationTimeline.rejected, (state, { payload }) => {
        state.errorTimeline =
          payload || "An unknown error occurred in fetchSimulationTimeline.";
        state.loadingTimeline = false;
      })

      //===================== FETCH COMPLETE MATCH DATA =====================
      .addCase(fetchCompleteMatchData.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchCompleteMatchData.fulfilled, (state, { payload }) => {
        // 'payload' is the final match data from match_analysis_generator
        // plus any default fields assigned by assignDefaultCompletionData.
        state.loadingStatistics = false;
        state.errorStatistics = null;

        // 1) Store the entire data as is
        state.completedMatchData = payload;

        // 2) Optionally unify final scoreboard with the local scoreboard
        const localGoals = payload.statistics?.local?.goals || [];
        const opponentGoals = payload.statistics?.opponent?.goals || [];

        // Merge final goals into our scoreboard
        state.scoreboard.local.goals = mergeUniqueEvents(
          state.scoreboard.local.goals,
          localGoals
        );
        state.scoreboard.opponent.goals = mergeUniqueEvents(
          state.scoreboard.opponent.goals,
          opponentGoals
        );

        // Merge final red cards if they exist
        const localRedCards = payload.statistics?.local?.stats?.redCards || [];
        const opponentRedCards =
          payload.statistics?.opponent?.stats?.redCards || [];

        state.scoreboard.local.redCards = mergeUniqueEvents(
          state.scoreboard.local.redCards,
          localRedCards
        );
        state.scoreboard.opponent.redCards = mergeUniqueEvents(
          state.scoreboard.opponent.redCards,
          opponentRedCards
        );

        // You could also handle final penalty shootout data if needed
        // e.g., state.completedMatchData.penaltyShootout
      })
      .addCase(fetchCompleteMatchData.rejected, (state, { payload }) => {
        state.errorStatistics =
          payload || "An unknown error occurred in fetchCompleteMatchData.";
        state.loadingStatistics = false;
      });
  },
});

export const {
  initiateSimulation,
  resetSimulationState,
  markNavigationTriggered,
  setError,
  storeLocalShotsData,
  storeOpponentShotsData,
  storePlayerHeatmap,
  setMatchPhase,
  updateScoreboard,
  updateGoals,
  updateRedCards,
  updateCompletedMatchData,
  setLastProcessedMinute,
} = matchSimulationSlice.actions;

/**
 * Retrieves the matchSimulation portion of the Redux store.
 */
export const selectMatchSimulationState = (rootState) =>
  rootState.matchSimulation;

export default matchSimulationSlice.reducer;
