/**
 * generateRealisticHeatmap
 *
 * Generates a 2D matrix for a player's realistic on-field coverage (heatmap).
 * It uses Gaussian lobes as base profiles for different positions,
 * adds random ephemeral lumps for scattered hotspots, incorporates fractal noise,
 * and applies smoothing and dropout to ensure high sparsity (most cells = 0).
 *
 * Positions supported (English/Spanish): GK (POR, ARQ), LB (LI), RB (LD), CB (DFC, DCB),
 * LWB (CAI), RWB (CAD), CDM (MCD, MDC), CM (MC, M), CAM (MCO, MP), LM (MI), RM (MD),
 * LW (EI), RW (ED), CF (SD, SEG), ST (DC, DEL).
 *
 * @param {string} position - The player's position abbreviation.
 * @param {number} intensityModifier - A modifier (usually 1–5) for global intensity.
 * @param {number} [rows=11] - Number of matrix rows.
 * @param {number} [cols=19] - Number of matrix columns.
 * @returns {number[][]} A rows×cols matrix with integer values between 0 and 5.
 */
export default function generateRealisticHeatmap(position, intensityModifier, rows = 11, cols = 19) {
  const synonyms = {
    GK: ["GK", "POR", "ARQ"],
    LB: ["LB", "LI"],
    RB: ["RB", "LD"],
    CB: ["CB", "DFC", "DCB"],
    LWB: ["LWB", "CAI"],
    RWB: ["RWB", "CAD"],
    CDM: ["CDM", "MCD", "MDC"],
    CM: ["CM", "MC", "M"],
    CAM: ["CAM", "MCO", "MP"],
    LM: ["LM", "MI"],
    RM: ["RM", "MD"],
    LW: ["LW", "EI"],
    RW: ["RW", "ED"],
    CF: ["CF", "SD", "SEG"],
    ST: ["ST", "DC", "DEL"],
    DEFAULT: ["DEFAULT"]
  };

  function unify(pos) {
    const up = (pos || "").trim().toUpperCase();
    for (const key in synonyms) {
      if (synonyms[key].includes(up)) {
        return key;
      }
    }
    return "DEFAULT";
  }

  const posKey = unify(position);

  // Base lumps for each position (Gaussian lobes)
  // GK lumps are narrower and focused around the goal area
  const profiles = {
    GK: [
      { rx: 0.45, cx: 0.05, rs: 0.05, cs: 0.02, w: 1.0 },
      { rx: 0.55, cx: 0.08, rs: 0.06, cs: 0.02, w: 0.7 }
    ],
    LB: [
      { rx: 0.30, cx: 0.15, rs: 0.12, cs: 0.05, w: 1.0 },
      { rx: 0.70, cx: 0.15, rs: 0.12, cs: 0.05, w: 0.8 }
    ],
    RB: [
      { rx: 0.30, cx: 0.85, rs: 0.12, cs: 0.05, w: 1.0 },
      { rx: 0.70, cx: 0.85, rs: 0.12, cs: 0.05, w: 0.8 }
    ],
    CB: [
      { rx: 0.30, cx: 0.25, rs: 0.10, cs: 0.04, w: 1.0 },
      { rx: 0.70, cx: 0.25, rs: 0.10, cs: 0.04, w: 1.0 },
      { rx: 0.50, cx: 0.40, rs: 0.18, cs: 0.07, w: 0.6 }
    ],
    DEFAULT: [
      { rx: 0.50, cx: 0.50, rs: 0.20, cs: 0.10, w: 1.0 }
    ]
  };

  const baseLumps = profiles[posKey] || profiles.DEFAULT;

  // Mirror lumps horizontally for outfield players (skip for GK)
  function mirrorLumps(lumps) {
    return lumps.map(l => ({ ...l, cx: 1 - l.cx }));
  }
  const mirroredLumps = posKey === "GK" ? [] : mirrorLumps(baseLumps);

  // Generate ephemeral lumps for scattered hotspots
  // Reduce or disable for GK to keep coverage minimal
  function generateEphemeralLumps(count) {
    const lumps = [];
    for (let i = 0; i < count; i++) {
      lumps.push({
        rx: Math.random(),
        cx: Math.random(),
        rs: Math.random() * 0.1 + 0.05,
        cs: Math.random() * 0.1 + 0.05,
        w: Math.random() * 0.5 + 0.2
      });
    }
    return lumps;
  }
  const ephemeralCount = posKey === "GK" ? 1 : 3;
  const ephemeralLumps = generateEphemeralLumps(ephemeralCount);

  // Combine all lumps and add slight randomization for realism
  function randomizeLumps(lumps) {
    return lumps.map(l => ({
      rx: Math.min(1, Math.max(0, l.rx + (Math.random() - 0.5) * 0.1)),
      cx: Math.min(1, Math.max(0, l.cx + (Math.random() - 0.5) * 0.1)),
      rs: Math.max(0.01, l.rs + (Math.random() - 0.5) * 0.02),
      cs: Math.max(0.01, l.cs + (Math.random() - 0.5) * 0.02),
      w: Math.max(0.1, l.w + (Math.random() - 0.5) * 0.3)
    }));
  }
  let allLumps = [...baseLumps, ...mirroredLumps, ...ephemeralLumps];
  allLumps = randomizeLumps(allLumps);

  function gaussian2D(x, y, cx, cy, sigmaX, sigmaY) {
    const dx = x - cx, dy = y - cy;
    return Math.exp(-0.5 * ((dx * dx) / (sigmaX * sigmaX) + (dy * dy) / (sigmaY * sigmaY)));
  }

  function perlinNoise(x, y) {
    return 0.5 * (Math.sin(x * Math.PI * 0.7 + y * 2.17) + 1);
  }

  function fractalNoise(x, y, octaves = 4, persistence = 0.5) {
    let total = 0, frequency = 1, amplitude = 1, maxValue = 0;
    for (let i = 0; i < octaves; i++) {
      total += perlinNoise(x * frequency, y * frequency) * amplitude;
      maxValue += amplitude;
      amplitude *= persistence;
      frequency *= 2;
    }
    return total / maxValue;
  }

  const rawMatrix = [];
  for (let r = 0; r < rows; r++) {
    const rowArray = [];
    const normR = r / (rows - 1);
    for (let c = 0; c < cols; c++) {
      const normC = c / (cols - 1);
      let value = 0;
      for (let i = 0; i < allLumps.length; i++) {
        const { rx, cx, rs, cs, w } = allLumps[i];
        value += gaussian2D(normR, normC, rx, cx, rs, cs) * w;
      }
      value += fractalNoise(normR, normC, 4, 0.5) * 0.1;
      value *= intensityModifier / 5;
      const cellVal = value < 0.4 ? 0 : Math.min(5, Math.floor(value));
      rowArray.push(cellVal);
    }
    rawMatrix.push(rowArray);
  }

  function smoothMatrix(mat) {
    const out = [];
    for (let r = 0; r < rows; r++) {
      out[r] = [];
      for (let c = 0; c < cols; c++) {
        let sum = 0, count = 0;
        for (let dr = -1; dr <= 1; dr++) {
          for (let dc = -1; dc <= 1; dc++) {
            const nr = r + dr, nc = c + dc;
            if (nr >= 0 && nr < rows && nc >= 0 && nc < cols) {
              sum += mat[nr][nc];
              count++;
            }
          }
        }
        out[r][c] = Math.round(sum / count);
      }
    }
    return out;
  }

  function dropout(mat, probability) {
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        if (mat[r][c] > 0 && Math.random() < probability) {
          mat[r][c] = 0;
        }
      }
    }
    return mat;
  }

  let processed = smoothMatrix(rawMatrix);
  processed = dropout(processed, 0.5);
  processed = smoothMatrix(processed);
  processed = dropout(processed, 0.3);

  let activeCount = 0;
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < cols; c++) {
      if (processed[r][c] > 0) activeCount++;
    }
  }
  const minActive = Math.floor(rows * cols * 0.1);
  if (activeCount < minActive) {
    for (let i = 0; i < (minActive - activeCount); i++) {
      const randR = Math.floor(Math.random() * rows);
      const randC = Math.floor(Math.random() * cols);
      processed[randR][randC] = 3 + Math.floor(Math.random() * 3);
    }
  }

  return processed;
}
