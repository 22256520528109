import { collection, getDocs, query, where, orderBy, getDoc, doc } from "firebase/firestore";
import { db } from "@/firebaseConfig";

const buildQuery = (collectionPath, conditions = [], orderByField = "timestamp", order = "desc") => {
  let q = collection(db, ...collectionPath.split("/"));
  if (conditions.length > 0) {
    conditions.forEach(([field, operator, value]) => {
      q = query(q, where(field, operator, value));
    });
  }
  return query(q, orderBy(orderByField, order));
};

export const fetchGeneralNotifications = async () => {
  const q = buildQuery("notifications/general", [["isActive", "==", true]]);
  try {
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp.toDate().toISOString(),
    }));
  } catch (error) {
    console.error("Error fetching general notifications:", error);
    throw new Error("Failed to fetch general notifications");
  }
};

export const fetchUserNotifications = async (userId) => {
  try {
    const notificationsDocRef = doc(db, "notifications", "users", userId, "notification_list");
    const notificationsDoc = await getDoc(notificationsDocRef);

    if (!notificationsDoc.exists()) {
      throw new Error("Notifications document does not exist");
    }

    const notificationsData = notificationsDoc.data();
    return (
      notificationsData.notifications?.map((notification) => ({
        ...notification,
        timestamp: notification.timestamp.toDate
          ? notification.timestamp.toDate().toISOString()
          : new Date(notification.timestamp).toISOString(),
      })) || []
    );
  } catch (error) {
    console.error(`Error fetching notifications for user ${userId}:`, error);
    throw new Error("Failed to fetch user notifications");
  }
};
