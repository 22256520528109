import { auth, functions } from "../firebaseConfig";
import { callFirebaseFunction } from "@/utils/firebaseFunctionCaller";
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";

class AuthServiceError extends Error {
  constructor(message, originalError = null) {
    super(message);
    this.name = "AuthServiceError";
    if (originalError) this.originalError = originalError;
  }
}

class AuthService {
  static instance = null;

  constructor() {
    if (AuthService.instance) return AuthService.instance;
    AuthService.instance = this;

    this.functions = functions;
    this.checkUsernameCallable = httpsCallable(this.functions, "check_username_availability");
    this.updateProfileImageCallable = httpsCallable(this.functions, "update_profile_image");
  }

  async verifyAuth() {
    try {
      const result = await this.testAuth();
      return result.data;
    } catch (error) {
      throw new AuthServiceError("Error verifying authentication", error);
    }
  }

  async handleUserDocument(userData, isNewUser = false) {
    if (!auth.currentUser) throw new AuthServiceError("User is not authenticated.");
    try {
      const payload = {
        username: userData.username,
        email: userData.email,
        avatar: userData.avatar || null,
        isNewUser: isNewUser,
        uid: auth.currentUser.uid,
      };
      const result = await callFirebaseFunction("trigger_user_update", payload);
      return result;
    } catch (error) {
      throw new AuthServiceError("Error updating user document", error);
    }
  }

  determineLoginMethodForProvider(providerIds) {
    return providerIds.length === 1 && providerIds[0] === "password" ? "password" : "social";
  }

  async signInWithProvider(providerClass, userDataProcessor) {
    try {
      const provider = new providerClass();
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      if (!user) throw new AuthServiceError("Authentication failed.");

      const isNewUser = !!result._tokenResponse?.isNewUser;
      const providerIds = user.providerData.map((pd) => pd.providerId);
      const loginMethod = this.determineLoginMethodForProvider(providerIds);
      const userData = userDataProcessor(user, loginMethod);

      await this.handleUserDocument(userData, isNewUser);
      return user;
    } catch (error) {
      throw new AuthServiceError("Error signing in with provider", error);
    }
  }

  async signInWithGoogle() {
    return await this.signInWithProvider(GoogleAuthProvider, (user, loginMethod) => ({
      username: (user.displayName || "User").split(" ")[0],
      email: user.email,
      avatar: user.photoURL || null,
      loginMethod,
    }));
  }

  async signInWithTwitter() {
    return this.signInWithProvider(TwitterAuthProvider, (user, loginMethod) => ({
      username: (user.displayName || "User").split(" ")[0],
      email: user.email || null,
      avatar: user.photoURL || null,
      loginMethod,
    }));
  }

  async signInWithEmail(emailOrUsername, password) {
    try {
      const { user } = await signInWithEmailAndPassword(auth, emailOrUsername, password);
      if (!user) throw new AuthServiceError("Authentication failed.");
      return user;
    } catch (error) {
      throw new AuthServiceError("Error signing in with email", error);
    }
  }

  async registerWithEmail(email, password, username) {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (!user) throw new AuthServiceError("Registration failed.");

      await updateProfile(user, { displayName: username });
      const userData = { username, email, avatar: user.photoURL || null };

      await this.handleUserDocument(userData, true);
      await sendEmailVerification(user);
      return user;
    } catch (error) {
      throw new AuthServiceError("Error registering with email", error);
    }
  }

  async resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw new AuthServiceError("Error resetting password", error);
    }
  }

  async logout() {
    try {
      await auth.signOut();
    } catch (error) {
      throw new AuthServiceError("Error logging out", error);
    }
  }

  async checkUsername(username) {
    if (!username || typeof username !== "string") {
      throw new AuthServiceError("Invalid input: 'username' must be a non-empty string.");
    }
    try {
      const { data } = await this.checkUsernameCallable({ username: username.trim() });
      if (!data || typeof data.available !== "boolean") {
        throw new AuthServiceError("Unexpected server response: 'available' field is missing or invalid.");
      }
      return data.available;
    } catch (error) {
      throw new AuthServiceError("An error occurred while checking username availability.", error);
    }
  }

  async updateProfileImage(imageUrl) {
    if (!auth.currentUser) throw new AuthServiceError("User is not authenticated.");
    try {
      const payload = { uid: auth.currentUser.uid, imageUrl };
      const result = await this.updateProfileImageCallable(payload);
      return result;
    } catch (error) {
      throw new AuthServiceError("Failed to update profile image", error);
    }
  }
}

const authService = new AuthService();
export default authService;
