/**
 * dataTransformers.js
 *
 * Provides advanced data transformation utilities for simulation responses,
 * ensuring properly structured timelines, scoreboards, and completion data.
 */

/**
 * Builds the payload for "match_analysis_generator" by sanitizing the timeline events
 * and scoreboard. This method helps ensure the backend receives valid data.
 * @param {Array} timeline - An array of timeline events.
 * @param {Object} teamA - Home team data (including name, players, etc.).
 * @param {Object} teamB - Away team data.
 * @param {Object} scoreboard - Contains local and opponent goals/cards.
 * @param {string} [language="en"] - Preferred language for analysis results.
 * @returns {Object} A final payload that can be safely sent to "match_analysis_generator".
 */
export function buildMatchAnalysisPayload(
  timeline,
  teamA,
  teamB,
  scoreboard,
  language = "en"
) {
  const validatedTimeline = Array.isArray(timeline)
    ? timeline.map((event) => {
        const player =
          event.player && typeof event.player === "object" ? event.player : {};
        return {
          ...event,
          player: {
            name:
              typeof player.name === "string" && player.name.trim() !== ""
                ? player.name
                : "N/A",
            number: typeof player.number === "number" ? player.number : 0,
          },
        };
      })
    : [];

  const defaultScoreboard = {
    local: { goals: [], redCards: [] },
    opponent: { goals: [], redCards: [] },
  };

  return {
    timeline: validatedTimeline,
    scoreboard: scoreboard || defaultScoreboard,
    local_team_name: teamA?.teamName || "Local Team",
    local_team_players: teamA?.players || {},
    opponent_team_name: teamB?.teamName || "Opponent Team",
    opponent_team_players: teamB?.players || {},
    language,
  };
}

/**
 * Ensures the final match data from "match_analysis_generator" uses default fields
 * for statistics, penaltyShootout, and postMatchSummary if any are missing.
 *
 * NOTE: The server response is shaped like:
 * {
 *   "success": true,
 *   "analysis": {
 *     "statistics": { ... },
 *     "penaltyShootout": { ... },
 *     "postMatchSummary": { ... }
 *   }
 * }
 */
export function assignDefaultCompletionData(data) {
  console.log("CompletionData -->", data);

  // If the server returns { success: true, analysis: {...} }, we handle that:
  const analysis = data.analysis || {};

  // If the server returns these fields at top-level, handle accordingly. 
  // But your logs show "analysis" key, so let's default there.
  const defaultStatistics = {
    local: { goals: [], stats: {} },
    opponent: { goals: [], stats: {} },
  };
  const defaultPenaltyShootout = { local: 0, opponent: 0 };
  const defaultPostMatchSummary = {
    title: "",
    subtitle: "",
    content: "",
    matchTopPlayers: [],
  };

  return {
    statistics: analysis.statistics || defaultStatistics,
    penaltyShootout: analysis.penaltyShootout || defaultPenaltyShootout,
    postMatchSummary: analysis.postMatchSummary || defaultPostMatchSummary,
  };
}
