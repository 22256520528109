
// src/contexts/AuthProvider.jsx

import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser, clearUser } from "../features/authSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";
import LoadingSpinner from '@/components/common/LoadingSpinner';

const AuthContext = createContext(null);
export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    let userDocUnsubscribe = null;
    const unsub = onAuthStateChanged(auth, (authUser) => {
      if (!authUser) {
        if (userDocUnsubscribe) userDocUnsubscribe();
        dispatch(clearUser());
        setCurrentUser(null);
        setLoading(false);
        return;
      }
      userDocUnsubscribe = onSnapshot(doc(db, "users", authUser.uid), (snapshot) => {
        const data = snapshot.exists() ? snapshot.data() : {};
        const userData = {
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName,
          photoURL: authUser.photoURL,
          emailVerified: authUser.emailVerified,
          loginMethod: data.loginMethod || 'password',
          ...data,
        };
        dispatch(setUser(userData));
        setCurrentUser(userData);
        setLoading(false);
      }, (error) => {
        console.error(error);
        dispatch(clearUser());
        setCurrentUser(null);
        setLoading(false);
      });
    });
    return () => {
      if (userDocUnsubscribe) userDocUnsubscribe();
      unsub();
    };
  }, [dispatch, auth]);

  return loading ? (
    <div className="flex items-center justify-center min-h-screen bg-gray-700">
      <LoadingSpinner />
    </div>
  ) : (
    <AuthContext.Provider value={{ loading, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
}
