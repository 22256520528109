// useAuth.js

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../features/authSlice';
import { useAuthContext } from '../contexts/AuthProvider';

const useAuth = (redirectPath) => {
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const { loading } = useAuthContext();

  useEffect(() => {
    if (!loading && !currentUser) {
      navigate(redirectPath);
    }
  }, [currentUser, loading, navigate, redirectPath]);

  return { currentUser, loading };
};

export default useAuth;