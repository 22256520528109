/**
 * formatTeamDataMiddleware.js
 *
 * A middleware that intercepts the "initiateSimulation" action, formats the
 * team data (home and away), determines the next phase, and dispatches the
 * async simulation. Once the simulation returns, if the match has reached
 * a certain condition, it fetches final match data.
 */

import {
  initiateMatchSimulation,
  fetchCompleteMatchData,
  setError,
  setMatchPhase,
} from "@/features/matchSimulation/matchSimulationSlice";
import { PHASES_OF_THE_MATCH } from "@/constants/phasesOfTheMatch";
import { buildMatchAnalysisPayload } from "@/utils/dataTransformers";

const DEFAULT_TACTICS = {
  formation: "4-3-3",
  playingStyle: "Balanced",
  offensiveStyle: { playersInBox: 2, style: "Balanced offense" },
  defensiveStyle: { depth: 20, width: 50, style: "Balanced defense" },
};

function determineNextPhase(currentPhase) {
  switch (currentPhase) {
    case PHASES_OF_THE_MATCH.FIRST_HALF:
      return PHASES_OF_THE_MATCH.SECOND_HALF;
    case PHASES_OF_THE_MATCH.SECOND_HALF:
      return PHASES_OF_THE_MATCH.EXTRA_TIME;
    case PHASES_OF_THE_MATCH.EXTRA_TIME:
      return PHASES_OF_THE_MATCH.PENALTIES;
    default:
      return PHASES_OF_THE_MATCH.FIRST_HALF;
  }
}

function parseSkills(skills, position) {
  if (!skills) return {};
  const arr = skills.split("|").map(Number);
  if (arr.length < 5) return {};
  const [a, b, c, d, e] = arr;
  if (position === "GK") {
    return {
      reflexes: a || 0,
      diving: b || 0,
      handling: c || 0,
      kicking: d || 0,
      speed: e || 0,
    };
  }
  return {
    pace: a || 0,
    defense: b || 0,
    dribbling: c || 0,
    shooting: d || 0,
    passing: e || 0,
  };
}

function buildPlayersList(playerArr = [], isStarter = false) {
  return playerArr.map((player) => ({
    name: player.playerName?.trim() || "Unnamed Player",
    position: player.position || "NA",
    number: player.playerNumber || "",
    skills: parseSkills(player.playerSkills, player.position),
    instruction: player.playerInstruction?.value || "",
    status: player.status || {
      goals: 0,
      hasYellowCard: false,
      hasDoubleYellowCard: false,
      hasRedCard: false,
      hasSubstitution: false,
      hasScoredOwnGoal: false,
      energyLevel: 100,
      processedMinutes: {},
    },
    ...(isStarter && {
      fieldPosition: {
        x: Math.round(player.vectorPosition?.x || 0),
        y: Math.round(player.vectorPosition?.y || 0),
      },
    }),
  }));
}

function unifyRoles(roles, players, isAwayTeam) {
  if (!players) return {};

  const roleMap = {
    captain: "team-captain",
    left_corner: "left-corner-taker",
    right_corner: "right-corner-taker",
    left_free_kick: "left-free-kick-taker",
    right_free_kick: "right-free-kick-taker",
    long_free_kick: "long-distance-free-kick-taker",
    penalty_kick: "penalty-kick-taker",
  };

  const mapped = {};
  const findByNumber = (num) =>
    players.starters.find((x) => x.number === num) ||
    players.substitutes.find((x) => x.number === num);

  if (isAwayTeam) {
    if (typeof roles !== "object") return mapped;
    Object.entries(roles).forEach(([key, val]) => {
      if (val == null) return;
      const found = findByNumber(val);
      if (found && roleMap[key]) {
        mapped[roleMap[key]] = found.name || "Away Player";
      }
    });
  } else {
    if (!Array.isArray(roles)) return mapped;
    roles.filter((role) => role != null).forEach(({ roleKey, playerNumber }) => {
      if (playerNumber == null) return;
      const found = findByNumber(playerNumber);
      if (found && roleMap[roleKey]) {
        mapped[roleMap[roleKey]] = found.name || "Home Player";
      }
    });
  }
  return mapped;
}


function formatTeamEntity(team, rolesData, isAwayTeam, globalTactics) {
  if (!team) return null;

  const starters = buildPlayersList(team.players, true);
  const substitutes = buildPlayersList(team.substitutes, false);
  const synergyPlayers = { starters, substitutes };

  const synergyRoles = unifyRoles(
    isAwayTeam ? team.teamRoles : rolesData,
    synergyPlayers,
    isAwayTeam
  );

  const finalTeam = {
    teamName:
      team.fullName?.trim() ||
      team.teamName?.trim() ||
      (isAwayTeam ? "Away Team" : "Home Team"),
    players: synergyPlayers,
    roles: synergyRoles,
  };

  if (isAwayTeam) {
    finalTeam.tactics = {
      ...DEFAULT_TACTICS,
      formation: team.formation || DEFAULT_TACTICS.formation,
      playingStyle: `Generic style for ${team.fullName || team.teamName}`,
    };
  } else {
    const {
      selectedGameStyleId,
      playingStyles = [],
      defensiveStyles = [],
      offensiveStyles = [],
    } = globalTactics || {};

    const chosenStyle = playingStyles.find((ps) => ps.id === selectedGameStyleId);
    if (!chosenStyle) {
      finalTeam.tactics = DEFAULT_TACTICS;
    } else {
      const styleVal = chosenStyle.value || "Balanced";
      const defObj =
        defensiveStyles.find((ds) => ds.id === chosenStyle.defense?.styleId) || {
          value: "Balanced defense",
        };
      const offObj =
        offensiveStyles.find((os) => os.id === chosenStyle.attack?.styleId) || {
          value: "Balanced offense",
        };

      finalTeam.tactics = {
        formation: team.formation || "4-3-3",
        playingStyle: styleVal,
        offensiveStyle: {
          playersInBox: chosenStyle.attack?.playersInBox || 2,
          style: offObj.value,
        },
        defensiveStyle: {
          depth: chosenStyle.defense?.depth || 20,
          width: chosenStyle.defense?.width || 50,
          style: defObj.value,
        },
      };
    }
  }

  return finalTeam;
}

function sanitizeCrestReference(teamStruct) {
  if (!teamStruct) return null;
  const cloned = { ...teamStruct };
  if (cloned.crestRef && typeof cloned.crestRef === "object") {
    cloned.crestRef = cloned.crestRef.id || null;
  }
  return cloned;
}

const formatTeamDataMiddleware = (store) => (next) => (action) => {
  if (action.type !== "matchSimulation/initiateSimulation") {
    return next(action);
  }

  // Extract from matchSimulation slice (NOT from state.timeline)
  const state = store.getState();
  const { scoreboard, phase, timeline } = state.matchSimulation;
  const { teamSelection, tactics } = state.team || {};
  const { rolesData = [] } = state.roles || {};

  const homeTeamData = teamSelection?.local?.team;
  const awayTeamData = teamSelection?.opponent?.team;

  const nextPhase = determineNextPhase(phase);
  store.dispatch(setMatchPhase(nextPhase));

  if (!homeTeamData || !awayTeamData) {
    console.error("[formatTeamDataMiddleware] Missing team data in Redux state.");
    store.dispatch(setError("Team data is incomplete or unavailable."));
    return next(action);
  }

  const synergyHome = formatTeamEntity(homeTeamData, rolesData, false, tactics);
  const synergyAway = formatTeamEntity(awayTeamData, rolesData, true, tactics);

  if (!synergyHome || !synergyAway) {
    console.error("[formatTeamDataMiddleware] Incomplete data after formatting teams.");
    store.dispatch(setError("Team data is incomplete after formatting."));
    return next(action);
  }

  const sanitizedHome = sanitizeCrestReference(synergyHome);
  const sanitizedAway = sanitizeCrestReference(synergyAway);

  // Prepare the main payload for the simulation
  const matchData = {
    match: {
      teamA: sanitizedHome,
      teamB: sanitizedAway,
      phase: nextPhase,
      existingTimeline: timeline,
      scoreboard: scoreboard,
      language: "es",
    },
  };

  store
    .dispatch(initiateMatchSimulation(matchData))
    .unwrap()
    .then(() => {
      // After simulation completes, retrieve the updated state
      const updatedState = store.getState();
      const {
        timeline: fullTimeline,
        scoreboard: finalScoreboard,
      } = updatedState.matchSimulation;

      // Example: if nextPhase === SECOND_HALF, build the final analysis payload
      if (nextPhase === PHASES_OF_THE_MATCH.SECOND_HALF) {
        const analysisPayload = buildMatchAnalysisPayload(
          fullTimeline,
          matchData.match.teamA,
          matchData.match.teamB,
          finalScoreboard,
          "es"
        );

        store
          .dispatch(fetchCompleteMatchData(analysisPayload))
          .unwrap()
          .catch((err) => {
            console.error("[formatTeamDataMiddleware] Error fetching final match data:", err);
            store.dispatch(
              setError("Error obtaining final match data: " + (err.message || "unknown"))
            );
          });
      }
    })
    .catch((error) => {
      console.error("[formatTeamDataMiddleware] Error in match simulation:", error);
      store.dispatch(
        setError("Failed to complete match simulation: " + (error.message || "unknown"))
      );
    });

  return next(action);
};

export default formatTeamDataMiddleware;
