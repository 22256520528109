// utils/scoreboardUtils.js

import { CATEGORY_TYPES } from "@/utils/categories";
import { CHRONOLOGY_SIDE } from "@/constants/chronologySide";

/**
 * Annotate timeline with cumulative score by summing goals.
 * @param {Array} timeline - Array of match events.
 * @param {Object} initialScoreboard - Starting scoreboard { local: { goals }, opponent: { goals } }.
 * @returns {Array} - Annotated timeline with 'score' field in each event.
 */
export const annotateTimelineWithScore = (timeline, initialScoreboard = { local: { goals: 0 }, opponent: { goals: 0 } }) => {
  let currentScore = { ...initialScoreboard };

  return timeline.map(event => {
    const { category, side } = event;

    if (
      [
        CATEGORY_TYPES.GOAL,
        CATEGORY_TYPES.OWN_GOAL,
        CATEGORY_TYPES.FREE_KICK_GOAL,
      ].includes(category)
    ) {
      const scoringSide =
        category === CATEGORY_TYPES.OWN_GOAL
          ? side === CHRONOLOGY_SIDE.LOCAL
            ? CHRONOLOGY_SIDE.OPPONENT
            : CHRONOLOGY_SIDE.LOCAL
          : side;

      if (scoringSide === CHRONOLOGY_SIDE.LOCAL) {
        currentScore.local.goals += 1;
      } else {
        currentScore.opponent.goals += 1;
      }
    }

    return {
      ...event,
      score: {
        local: currentScore.local.goals,
        opponent: currentScore.opponent.goals,
      },
    };
  });
};
