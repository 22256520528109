// constants/phasesOfTheMatch.js

export const PHASES_OF_THE_MATCH = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  FIRST_HALF: "FIRST_HALF",
  SECOND_HALF: "SECOND_HALF",
  EXTRA_TIME: "EXTRA_TIME",
  PENALTIES: "PENALTIES",
  FULL_MATCH: "FULL_MATCH",
});
