// src/features/teamSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { calculatePlayerPositions } from "../utils/playerUtils";
import { TEAM_TYPE } from "@/constants/teamTypes";
import { selectMyIdealTeam, setTeamRef } from "./idealTeamSlice";

export const updatePlayerInstructionAsync = createAsyncThunk(
  "team/updatePlayerInstruction",
  async ({ playerId, playerInstruction }, { getState }) => {
    const { teamSelection } = getState().team;
    const homePlayers = teamSelection.local?.team?.players || [];
    const homeSubstitutes = teamSelection.local?.team?.substitutes || [];

    const allPlayers = [...homePlayers, ...homeSubstitutes];
    const player = allPlayers.find((p) => p.id === playerId);

    if (player) {
      return { playerId, playerInstruction };
    }
    throw new Error("Player not found");
  }
);

const initialState = {
  selectedSeason: null,
  selectedCompetition: {
    id: null,
    type: null,
    name: null,
  },
  loadingLocalTeam: false,
  loadingOpponent: false,
  errorLocal: false,
  errorOpponent: false,
  selectedTeamType: null,
  tactics: {
    defensiveStyles: [
      {
        id: 1,
        name: "Retrasado",
        description:
          "Esta táctica mantiene la formación y les ofrece a tus oponentes más posesión de balón. La delantera permanece pasiva, así que podrías tener menos opciones de ataque cuando recuperes el balón.",
        value: "Passive defense, allows opponent possession.",
      },
      {
        id: 2,
        name: "Equilibrado",
        description:
          "Un estilo equilibrado en el que tu equipo mueve el balón hacia el centro de la cancha y mantiene una formación neutral.",
        value: "Balanced play, neutral formation.",
      },
      {
        id: 3,
        name: "Presion al ataque",
        description:
          "El equipo mantendrá la formación hasta tener la oportunidad de presionar. Lo harán cuando el rival haga un toque fuerte, haya un balón suelto o deban controlar un pase difícil cerca de su arco.",
        value: "Press when opponent is vulnerable.",
      },
      {
        id: 4,
        name: "Presion constante",
        description:
          "Esta táctica le pone mucha presión al equipo rival. Puede dejar a tu equipo vulnerable, dado que cansa más y hace más probable quedar fuera de posición.",
        value: "Constant pressure, risk of being out of position.",
      },
    ],
    offensiveStyles: [
      {
        id: 1,
        name: "Balanceado",
        description:
          "Esta táctica se usa para un equipo equilibrado que mantiene la formación mientras arma una jugada de ataque. Los futbolistas darán apoyo y correrán cuando crean que es el momento indicado.",
        value: "Balanced attack, formation support.",
      },
      {
        id: 2,
        name: "Armado lento",
        description:
          "El equipo dará más apoyo en el armado del ataque en lugar de realizar carreras de ataque. Esta táctica refuerza un estilo de juego con pases cortos, pero hará que el equipo tarde más en armarse.",
        value: "Slow build-up, focused on short passes.",
      },
      {
        id: 3,
        name: "Armado rapido",
        description:
          "Esta táctica anima al equipo a que se adelanten para armar rápido la jugada, pero si pierdes posesión del balón, te puedes exponer a un contraataque.",
        value: "Fast build-up, risk of counterattack.",
      },
      {
        id: 4,
        name: "Pase largo",
        description:
          "El equipo correrá al espacio tras la línea defensiva rival o hacia un futbolista de referencia buscando pases largos, saltando la zona del mediocampo. Atacantes veloces y con buen posicionamiento ofensivo son mejores para esta táctica.",
        value: "Long passes, exploit spaces behind defense.",
      },
    ],
    playingStyles: [
      {
        id: 0,
        name: "Mi táctica",
        description: "Crea una instrucción opcional para el equipo:",
        defense: {
          styleId: 1,
          depth: 50,
          width: 50,
        },
        attack: {
          styleId: 1,
          playersInBox: 3,
        },
        value: "",
      },
      {
        id: 1,
        name: "Estandar",
        description:
          "Este estilo tiene un enfoque equilibrado en cuanto al juego y hace hincapié en mantener la formación, lo cual ayudará a conservar la fortaleza defensiva y, a la vez, seguir siendo una amenaza en ataque.",
        defense: {
          styleId: 2,
          depth: 20,
          width: 60,
        },
        attack: {
          styleId: 2,
          playersInBox: 2,
        },
        value: "Standard balanced approach, solid defense.",
      },
      {
        id: 2,
        name: "Por bandas",
        description:
          "Se aprovecha todo el ancho de la cancha cuando se está en posesión del balón y se intenta que el esférico llegue rápidamente a los atacantes cerca de las bandas, los laterales se suman al ataque por los costados para una mayor amenaza ofensiva.",
        defense: {
          styleId: 2,
          depth: 30,
          width: 90,
        },
        attack: {
          styleId: 3,
          playersInBox: 3,
        },
        value: "Utilize width for rapid wing attacks.",
      },
      {
        id: 3,
        name: "Posesión",
        description:
          "Estilo de juego basado en la posesión. Tus futbolistas están cómodos con el balón por toda la cancha y usan pases cortos y rotaciones. Al presentarse la oportunidad, los delanteros aprovechan los espacios en la defensa rival para atacar con éxito.",
        defense: {
          styleId: 3,
          depth: 40,
          width: 70,
        },
        attack: {
          styleId: 2,
          playersInBox: 4,
        },
        value: "Possession-focused, short passes and rotations.",
      },
      {
        id: 4,
        name: "Contraataque",
        description:
          "Este estilo no arriesga y defiende en la propia zona defensiva. Los futbolistas defienden el área y, al recuperar la posesión, hacen llegar el balón rápidamente a los atacantes para que corran al espacio detrás de la defensa rival.",
        defense: {
          styleId: 1,
          depth: 25,
          width: 50,
        },
        attack: {
          styleId: 3,
          playersInBox: 5,
        },
        value: "Defensive, quick transitions to attackers.",
      },
      {
        id: 5,
        name: "Presión Alta",
        description:
          "Estilo de juego basado en la fuerza y el atletismo que permite a tus futbolistas saltarse el mediocampo, permitiendo que el balón llegue rápido a tus potentes delanteros, quienes aprovechan el espacio a espalda de la defensa y disputan el balón.",
        defense: {
          styleId: 2,
          depth: 70,
          width: 80,
        },
        attack: {
          styleId: 4,
          playersInBox: 5,
        },
        value: "High pressure, fast transitions to powerful forwards.",
      },
      {
        id: 6,
        name: "Todos atrás",
        description:
          "Tu equipo juega basado en una defensa sólida e implacable. Siempre disciplinado y bien organizado sin el balón, suelen jugar con una defensa atrasada que ahoga los ataques rivales antes de recuperar el balón para intentar construir desde ahí.",
        defense: {
          styleId: 1,
          depth: 5,
          width: 40,
        },
        attack: {
          styleId: 1,
          playersInBox: 1,
        },
        value: "Solid defense, organized play without the ball.",
      },
    ],
    selectedGameStyleId: 1,
  },
  alertVisibility: {
    gameStyle: true,
    defensiveStyle: true,
    offensiveStyle: true,
  },
  teamSelection: {
    local: { team: null, teamID: null, crestRef: null },
    opponent: { team: null, teamID: null, crestRef: null },
  },
  formations: [
    "4-4-2",
    "4-3-3",
    "4-2-3-1",
    "3-5-2",
    "4-1-4-1",
    "4-3-2-1",
    "4-2-4",
    "4-3-1-2",
    "3-4-3",
    "4-1-3-2",
    "5-3-2",
    "5-4-1",
    "3-4-1-2",
    "3-4-2-1",
    "3-3-4",
    "3-6-1",
    "4-5-1",
    "4-4-1-1",
  ],
  selectedCompetitionType: "leagues",
  selectedCompetitionId: null,
  loadingHome: false,
  loadingAway: false,
  error: null,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    updatePlayerStatuses: (state, action) => {
      const { teamStatuses } = action.payload;

      const processTeamStatuses = (team, updates, teamType) => {
        if (!team || !Array.isArray(updates)) {
          console.warn(
            `[processTeamStatuses] Datos inválidos para equipo ${teamType}`
          );
          return team;
        }
        const mapUpdates = new Map(
          updates.map((u) => [u.playerNumber, u.status])
        );
        const processedPlayers = team.players.map((player) => {
          const patch = mapUpdates.get(player.playerNumber);
          if (!patch) return player;
          return {
            ...player,
            status: {
              ...player.status,
              ...patch,
              processedMinutes: {
                ...(player.status?.processedMinutes || {}),
                ...(patch.processedMinutes || {}),
              },
            },
          };
        });

        return {
          ...team,
          players: processedPlayers,
        };
      };

      const { local, opponent } = teamStatuses;

      if (state.teamSelection.local?.team) {
        state.teamSelection.local.team = processTeamStatuses(
          state.teamSelection.local.team,
          local,
          "local"
        );
      } else {
        console.warn("[updatePlayerStatuses] No local team to update.");
      }

      if (state.teamSelection.opponent?.team) {
        state.teamSelection.opponent.team = processTeamStatuses(
          state.teamSelection.opponent.team,
          opponent,
          "opponent"
        );
      } else {
        console.warn("[updatePlayerStatuses] No opponent team to update.");
      }
    },

    selectTeamFromCard: (state, action) => {
      const { teamRef, teamType, crestRef } = action.payload;
      state.teamSelection[teamType].teamID = teamRef;
      state.teamSelection[teamType].crestRef = crestRef.id;
      state.teamSelection[teamType].team = {
        teamID: teamRef,
        players: [],
        substitutes: [],
      };
      if (teamType === TEAM_TYPE.MY_TEAM) {
        state.selectedTeamType = TEAM_TYPE.MY_TEAM;
      }
    },

    resetTeamSelection: (state, action) => {
      const teamType = action.payload;
      state.teamSelection[teamType] = {
        team: null,
        teamID: null,
        crestRef: null,
      };
    },

    setErrorLocal: (state, action) => {
      state.errorLocal = action.payload;
    },

    setErrorOpponent: (state, action) => {
      state.errorOpponent = action.payload;
    },

    setTeams: (state, action) => {
      state.teams = action.payload;
    },

    selectTeam: (state, action) => {
      const { teamType, teamData, season } = action.payload;
      if (!teamData || !state.teamSelection[teamType]) return;

      const mergedData = { ...teamData };
      if (mergedData.players?.length) {
        const isHost = teamType === TEAM_TYPE.LOCAL;
        try {
          const positions = calculatePlayerPositions(
            mergedData.players,
            mergedData.formation,
            isHost
          );
          mergedData.players = mergedData.players.map((player) => ({
            ...player,
            vectorPosition: {
              ...player.vectorPosition,
              x: positions[player.id]?.x || player.vectorPosition.x,
              y: positions[player.id]?.y || player.vectorPosition.y,
              initialX:
                positions[player.id]?.x || player.vectorPosition.initialX,
              initialY:
                positions[player.id]?.y || player.vectorPosition.initialY,
            },
            status: {
              goals: 0,
              hasYellowCard: false,
              hasDoubleYellowCard: false,
              hasRedCard: false,
              hasSubstitution: false,
              hasScoredOwnGoal: false,
              energyLevel: 100,
            },
          }));
        } catch (err) {
          console.error("Error in calculatePlayerPositions:", err);
        }
      }

      if (mergedData.teamRoles?.captain !== undefined) {
        mergedData.players = mergedData.players.map((player) => ({
          ...player,
          isCaptain: player.playerNumber === mergedData.teamRoles.captain,
        }));
        mergedData.substitutes = mergedData.substitutes.map((player) => ({
          ...player,
          isCaptain: player.playerNumber === mergedData.teamRoles.captain,
        }));
      }

      const existingCrest = state.teamSelection[teamType]?.crestRef;
      mergedData.crestRef = mergedData.crestRef || existingCrest || null;

      state.teamSelection[teamType] = {
        ...state.teamSelection[teamType],
        crestRef: mergedData.crestRef,
        team: mergedData,
        teamID: mergedData.teamID,
        season,
      };
    },

    setLoadingLocalTeam: (state, action) => {
      state.loadingLocalTeam = action.payload;
    },

    setLoadingOpponent: (state, action) => {
      state.loadingOpponent = action.payload;
    },

    setAlertVisibility: (state, action) => {
      const { alertType, isVisible } = action.payload;
      state.alertVisibility[alertType] = isVisible;
    },

    selectGameStyle: (state, action) => {
      state.tactics.selectedGameStyleId = action.payload.id;
    },

    updateTacticDetails: (state, action) => {
      const tactic = state.tactics.playingStyles.find(
        (style) => style.id === 0
      );
      if (!tactic) return;

      for (const [key, value] of Object.entries(action.payload)) {
        const keys = key.split(".");
        let target = tactic;
        for (let i = 0; i < keys.length - 1; i++) {
          if (target[keys[i]] === undefined) return;
          target = target[keys[i]];
        }
        target[keys[keys.length - 1]] = value;
      }
    },

    changeFormation: (state, action) => {
      const { teamType, newFormation } = action.payload;
      const team = state.teamSelection.local.team;

      if (team && team.players && team.players.length > 0) {
        team.formation = newFormation;
        try {
          const playerPositions = calculatePlayerPositions(
            team.players,
            newFormation,
            teamType === TEAM_TYPE.LOCAL
          );
          team.players = team.players.map((player) => ({
            ...player,
            vectorPosition: {
              ...player.vectorPosition,
              x: playerPositions[player.id]?.x || player.vectorPosition.x,
              y: playerPositions[player.id]?.y || player.vectorPosition.y,
              initialX:
                playerPositions[player.id]?.x || player.vectorPosition.initialX,
              initialY:
                playerPositions[player.id]?.y || player.vectorPosition.initialY,
            },
          }));
        } catch (error) {
          console.error("Error calculating player positions:", error);
        }
      } else {
        console.error("Team not found or no players for:", teamType);
      }
    },

    updatePlayerPosition: (state, action) => {
      const { playerId, newX, newY } = action.payload;
      const team = state.teamSelection.local?.team;
      if (!team) {
        console.warn("Local team not found. Unable to update player position.");
        return;
      }

      const playerIndex = team.players.findIndex((p) => p.id === playerId);
      if (playerIndex === -1) {
        console.warn(
          `Player with id ${playerId} not found. Unable to update position.`
        );
        return;
      }

      const player = team.players[playerIndex];
      if (player.position === "POR") {
        console.warn("Cannot move goalkeeper's position manually.");
        return;
      }

      const clampedX = Math.max(0, Math.min(100, newX));
      const clampedY = Math.max(0, Math.min(100, newY));

      const updatedPlayer = {
        ...player,
        vectorPosition: { x: clampedX, y: clampedY },
      };
      team.players = [
        ...team.players.slice(0, playerIndex),
        updatedPlayer,
        ...team.players.slice(playerIndex + 1),
      ];
    },

    setSubstitutes: (state, action) => {
      state.substitutes = action.payload;
    },

    setSelectedTeamType: (state, action) => {
      state.selectedTeamType = action.payload;
    },

    setCompetitionType: (state, action) => {
      state.selectedCompetitionType = action.payload;
    },

    swapPlayers: (state, action) => {
      const { playerOne, playerTwo } = action.payload;
      const team = state.teamSelection.local?.team;
      if (!team) return;

      const isPlayerOneSub = team.substitutes.some(
        (sub) => sub.id === playerOne.player.id
      );
      const isPlayerTwoSub = team.substitutes.some(
        (sub) => sub.id === playerTwo.player.id
      );

      const arrOne = isPlayerOneSub ? team.substitutes : team.players;
      const arrTwo = isPlayerTwoSub ? team.substitutes : team.players;

      const idxOne = arrOne.findIndex((p) => p.id === playerOne.player.id);
      const idxTwo = arrTwo.findIndex((p) => p.id === playerTwo.player.id);

      if (idxOne !== -1 && idxTwo !== -1) {
        const temp = { ...arrOne[idxOne] };
        arrOne[idxOne] = { ...arrTwo[idxTwo] };
        arrTwo[idxTwo] = temp;

        const swapPos = arrOne[idxOne].vectorPosition;
        arrOne[idxOne].vectorPosition = arrTwo[idxTwo].vectorPosition;
        arrTwo[idxTwo].vectorPosition = swapPos;
      }
    },

    setSelectedSeason: (state, action) => {
      state.selectedSeason = action.payload;
    },

    setSelectedCompetition: (state, action) => {
      state.selectedCompetition = {
        id: action.payload.id,
        type: action.payload.type,
        name: action.payload.name,
      };
    },

    setLoadingHome: (state, action) => {
      state.loadingHome = action.payload;
    },

    setLoadingAway: (state, action) => {
      state.loadingAway = action.payload;
    },

    resetSeasonAndCompetition(state) {
      state.selectedCompetition = { id: null, type: null, name: null };
      state.selectedSeason = null;
    },

    updateCaptain(state, action) {
      const { newCaptainId } = action.payload;
      const localPlayers = state.teamSelection.local.team.players;
      localPlayers.forEach((player) => {
        player.isCaptain = false;
      });
      const newCaptain = localPlayers.find(
        (player) => player.id === newCaptainId
      );
      if (newCaptain) {
        newCaptain.isCaptain = true;
      }
    },

    resetTeamSelectionState: (state) => {
      state.teamSelection = {
        local: { team: null, teamID: null, crestRef: null },
        opponent: { team: null, teamID: null, crestRef: null },
      };
    },

    resetSelectedCompetition(state) {
      state.selectedCompetition = { id: null, type: null, name: null };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updatePlayerInstructionAsync.fulfilled, (state, action) => {
      const { playerId, playerInstruction } = action.payload;
      const homePlayers = state.teamSelection.local?.team?.players || [];
      const homeSubs = state.teamSelection.local?.team?.substitutes || [];

      const allHome = [...homePlayers, ...homeSubs];
      const foundPlayer = allHome.find((p) => p.id === playerId);
      if (foundPlayer) {
        foundPlayer.playerInstruction = playerInstruction;
      } else {
        console.error(
          "Player not found in extraReducer instruction set",
          playerId
        );
      }
    });
  },
});

export const {
  resetSeasonAndCompetition,
  setSelectedCompetition,
  setSelectedSeason,
  selectTeam,
  setLoadingLocalTeam,
  setLoadingOpponent,
  setErrorLocal,
  setErrorOpponent,
  setTeams,
  setAlertVisibility,
  selectGameStyle,
  updateTacticDetails,
  changeFormation,
  updatePlayerPosition,
  setSubstitutes,
  setSelectedTeamType,
  setCompetitionType,
  swapPlayers,
  selectTeamFromCard,
  resetTeamSelection,
  setLoadingHome,
  setLoadingAway,
  updateCaptain,
  resetTeamSelectionState,
  resetSelectedCompetition,
  updatePlayerStatuses,
} = teamSlice.actions;

export default teamSlice.reducer;
