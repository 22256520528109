import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchGeneralNotifications,
  fetchUserNotifications,
} from "@services/notificationsService";
import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
} from "@/constants/statusConstants";

const initialState = {
  general: [],
  user: [],
  status: STATUS_IDLE,
  error: null,
};

export const loadGeneralNotifications = createAsyncThunk(
  "notifications/loadGeneral",
  async () => await fetchGeneralNotifications()
);

export const loadUserNotifications = createAsyncThunk(
  "notifications/loadUser",
  async (userId) => await fetchUserNotifications(userId)
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    markAsRead: (state, action) => {
      const { id, type } = action.payload;
      state[type] = state[type].map((n) =>
        n.id === id ? { ...n, read: true } : n
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGeneralNotifications.fulfilled, (state, action) => {
        state.general = action.payload;
        state.status = STATUS_SUCCEEDED;
      })
      .addCase(loadUserNotifications.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = STATUS_SUCCEEDED;
      })
      .addCase(loadGeneralNotifications.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(loadUserNotifications.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(loadGeneralNotifications.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = STATUS_FAILED;
      })
      .addCase(loadUserNotifications.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = STATUS_FAILED;
      });
  },
});

export const { markAsRead } = notificationsSlice.actions;

export default notificationsSlice.reducer;
