/**
 * generatePositionedPassVectors
 *
 * Generates pass vectors on a normalized [0..1] pitch, biased by position,
 * avoiding lateral extremes. For ST/CF, uses advanced patterns (e.g., Griezmann-like).
 * Returns geometry for each arrow as two SVG path commands:
 *   - lineD: "M sx sy L ex ey"
 *   - tipD:  "M ex ey L tipLeft.x tipLeft.y L tipRight.x tipRight.y Z"
 *
 * @param {string} position - Player position abbreviation ("CB", "LB", "CM", "ST", etc.).
 * @param {number} count - Number of passes to generate.
 * @returns {Array<{
*   sx: number, sy: number,
*   ex: number, ey: number,
*   lineD: string,
*   tipD: string
* }>}
*/
export default function generatePositionedPassVectors(position = "DEFAULT", count = 40) {
 const synonyms = {
   GK: ["GK", "POR", "ARQ"],
   CB: ["CB", "DFC", "DCB"],
   LB: ["LB", "LI"],
   RB: ["RB", "LD"],
   LWB: ["LWB", "CAI"],
   RWB: ["RWB", "CAD"],
   CDM: ["CDM", "MCD", "MDC"],
   CM: ["CM", "MC", "M"],
   CAM: ["CAM", "MCO", "MP"],
   LM: ["LM", "MI"],
   RM: ["RM", "MD"],
   LW: ["LW", "EI"],
   RW: ["RW", "ED"],
   CF: ["CF", "SD", "SEG"],
   ST: ["ST", "DC", "DEL"],
   DEFAULT: ["DEFAULT"]
 };

 // Basic zones (non-forwards). Start is clamped to avoid edges.
 const defaultZones = {
   GK:   { minX: 0.05, maxX: 0.25, minY: 0.35, maxY: 0.65 },
   CB:   { minX: 0.15, maxX: 0.5,  minY: 0.2,  maxY: 0.8  },
   LB:   { minX: 0.05, maxX: 0.3,  minY: 0.05, maxY: 0.95 },
   RB:   { minX: 0.7,  maxX: 0.95, minY: 0.05, maxY: 0.95 },
   CM:   { minX: 0.25, maxX: 0.75, minY: 0.2,  maxY: 0.8  },
   DEFAULT: { minX: 0.05, maxX: 0.95, minY: 0.05, maxY: 0.95 }
 };

 // Forwards (ST/CF) pass patterns with angles in degrees.
 const forwardPassPatterns = [
   { angleRange: [-15, 15], lengthRange: [0.1, 0.3], weight: 0.35 },
   { angleRange: [-20, 20], lengthRange: [0.3, 0.5], weight: 0.2 },
   { angleRange: [60, 120], lengthRange: [0.1, 0.3], weight: 0.2 },
   { angleRange: [-120, -60], lengthRange: [0.1, 0.3], weight: 0.15 },
   { angleRange: [150, 180], lengthRange: [0.1, 0.25], weight: 0.1 }
 ];

 // Forwards' start zone
 const stZone = { minX: 0.2, maxX: 0.8, minY: 0.15, maxY: 0.85 };

 function unify(pos) {
   const up = (pos || "").trim().toUpperCase();
   for (const k in synonyms) {
     if (synonyms[k].includes(up)) return k;
   }
   return "DEFAULT";
 }
 const posKey = unify(position);

 function pickForwardPattern(patterns) {
   const sumWeights = patterns.reduce((acc, p) => acc + p.weight, 0);
   let r = Math.random() * sumWeights;
   for (const pat of patterns) {
     if (r < pat.weight) return pat;
     r -= pat.weight;
   }
   return patterns[patterns.length - 1];
 }

 // Final chosen zone
 const zone =
   posKey === "ST" || posKey === "CF"
     ? stZone
     : defaultZones[posKey] || defaultZones.DEFAULT;

 const passes = [];
 for (let i = 0; i < count; i++) {
   const sx = rand(zone.minX, zone.maxX);
   const sy = rand(zone.minY, zone.maxY);

   let ex, ey;
   if (posKey === "ST" || posKey === "CF") {
     const pat = pickForwardPattern(forwardPassPatterns);
     const angleDeg = rand(pat.angleRange[0], pat.angleRange[1]);
     const angleRad = (angleDeg * Math.PI) / 180;
     const length = rand(pat.lengthRange[0], pat.lengthRange[1]);
     ex = sx + length * Math.cos(angleRad);
     ey = sy + length * Math.sin(angleRad);
   } else {
     const angle = Math.random() * 2 * Math.PI;
     const length = rand(0.05, 0.4);
     ex = sx + length * Math.cos(angle);
     ey = sy + length * Math.sin(angle);
   }
   ex = clamp(ex, 0.05, 0.95);
   ey = clamp(ey, 0.05, 0.95);

   // Build arrow geometry: line from (sx, sy) to (ex, ey), plus arrow tip
   const lineD = `M ${sx} ${sy} L ${ex} ${ey}`;

   // Arrow tip geometry
   const headLen = 0.03;
   const headAngle = Math.PI / 7;
   const mainAngle = Math.atan2(ey - sy, ex - sx);
   const tipLeftX = ex - headLen * Math.cos(mainAngle - headAngle);
   const tipLeftY = ey - headLen * Math.sin(mainAngle - headAngle);
   const tipRightX = ex - headLen * Math.cos(mainAngle + headAngle);
   const tipRightY = ey - headLen * Math.sin(mainAngle + headAngle);

   // tipD is a small triangle at (ex, ey)
   const tipD = [
     `M ${ex} ${ey}`,
     `L ${tipLeftX} ${tipLeftY}`,
     `L ${tipRightX} ${tipRightY}`,
     `Z`
   ].join(" ");

   passes.push({
     sx, sy, ex, ey,
     lineD,
     tipD
   });
 }
 return passes;
}

function rand(min, max) {
 return min + Math.random() * (max - min);
}

function clamp(value, mn, mx) {
 return value < mn ? mn : value > mx ? mx : value;
}
