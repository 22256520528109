
// contexts/SimulationContext.js

import React, { createContext, useContext, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initiateSimulation } from '@/features/matchSimulation/matchSimulationSlice';

const SimulationContext = createContext();

export const SimulationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const simulateMatch = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const confirmSimulation = useCallback(() => {
    dispatch(initiateSimulation());
    setIsModalOpen(false);
  }, [dispatch]);

  const cancelSimulation = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <SimulationContext.Provider value={{ simulateMatch, confirmSimulation, cancelSimulation, isModalOpen }}>
      {children}
    </SimulationContext.Provider>
  );
};

export const useSimulation = () => {
  return useContext(SimulationContext);
};