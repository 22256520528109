// authSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "@/services/authService";

const initialState = {
  currentUser: null,
  error: null,
  usernameAvailability: {
    loading: false,
    available: null,
    error: null,
  },
};

export const checkUsername = createAsyncThunk(
  "checkUsername",
  async (username, { rejectWithValue }) => {
    try {
      return await authService.checkUsername(username);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "auth/loginWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const user = await authService.signInWithGoogle();

      return user;
    } catch (error) {
      console.error("Error en loginWithGoogle thunk:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const loginWithEmail = createAsyncThunk(
  "auth/loginWithEmail",
  async ({ emailOrUsername, password }, { rejectWithValue }) => {
    try {
      const user = await authService.signInWithEmail(emailOrUsername, password);
      return user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({ email, password, username }, { rejectWithValue }) => {
    try {
      const user = await authService.registerWithEmail(email, password, username);
      return user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  "auth/resetPassword",
  async (email, { rejectWithValue }) => {
    try {
      await authService.resetPassword(email);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateProfileImage = createAsyncThunk(
  "auth/updateProfileImage",
  async ({ imageUrl }, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.updateProfileImage(imageUrl);
      dispatch(updateAvatar(imageUrl));
      return imageUrl;
    } catch (error) {
      console.error("Error updating profile image:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await authService.logout();
      dispatch(clearUser());
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginWithTwitter = createAsyncThunk(
  "auth/loginWithTwitter",
  async (_, { rejectWithValue }) => {
    try {
      const user = await authService.signInWithTwitter();
      return user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.currentUser = action.payload;
    },
    clearUser(state) {
      state.currentUser = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    updateAvatar(state, action) {
      if (state.currentUser) {
        state.currentUser.avatar = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUsername.pending, (state) => {
        state.usernameAvailability.loading = true;
        state.usernameAvailability.error = null;
      })
      .addCase(checkUsername.fulfilled, (state, action) => {
        state.usernameAvailability.loading = false;
        state.usernameAvailability.available = action.payload;
      })
      .addCase(checkUsername.rejected, (state, action) => {
        state.usernameAvailability.loading = false;
        state.usernameAvailability.error = action.payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("auth/") && action.type.endsWith("/rejected"),
        (state, action) => {
          state.error =
            action.payload ||
            "Ha ocurrido un error. Por favor, inténtalo de nuevo.";
        }
      );
  },
});

export const { setUser, clearUser, setError, clearError, updateAvatar } =
  authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectError = (state) => state.auth.error;
export const selectUsernameAvailability = (state) =>
  state.auth.usernameAvailability;
